<template>
  <article class="partner-error-page">
    <app-container class="partner-error-page__content">
      <main class="partner-error-page__content__text">
        <app-alert
          v-if="isPartnerAccess && message"
          v-test="'partner-error-page-alert'"
          :message="message"
          type="error"
        />
      </main>
    </app-container>
  </article>
</template>

<script>
import AppContainer from '@/components/ui/container/AppContainer.vue'
import AppAlert from '@/components/ui/alert/AppAlert.vue'
import partnerService from '@/services/partnerService'

export default {
  name: 'PartnerErrorPage',
  components: {
    AppContainer,
    AppAlert,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  computed: {
    isPartnerAccess () {
      return partnerService.isPartnerAccess()
    },
  },
}
</script>
<style lang="scss" scoped>
.partner-error-page {
  height: 100%;
  overflow: auto;

  &__content {
    padding: map-get($spacers, 6) map-get($spacers, 6) 0 map-get($spacers, 6) !important;
    position: relative;
    height: 100%;
  }
}
</style>