import { ROUTE_NAMES } from '@/modules/patient/constants'
import { PARTNER_PAGE_ROUTES } from '@/constants/partnerPageRoutes'
import { BASE_ROUTE_NAMES } from '@/constants/baseRouteNames'

import { getFromAPI, postToAPI } from '@/services/api'
import store from '@/store/instance'
import NovaTools from '@/nova-tools/NovaTools'

import { getEnv } from '@/utils/functions/env.js'

export default {
  isPartnerAccess () {
    return getEnv('VUE_APP_PARTNER_ENV') === 'true'
  },

  setPartnerAccess (isPartnerAccess) {
    localStorage.setItem('isPartnerAccess', JSON.stringify(isPartnerAccess))
  },

  isPartnerAccessLoggedOut () {
    if (! this.isPartnerAccess()) {
      return false
    }
    const isPartnerAccessLoggedOut = localStorage.getItem('isPartnerAccessLoggedOut')

    return (isPartnerAccessLoggedOut !== null) ? JSON.parse(isPartnerAccessLoggedOut) : false
  },

  setPartnerAccessLoggedOut (isPartnerAccessLoggedOut) {
    localStorage.setItem('isPartnerAccessLoggedOut', JSON.stringify(isPartnerAccessLoggedOut))
  },

  removeLocalPartnerAccessLoggedOut () {
    localStorage.removeItem('isPartnerAccessLoggedOut')
  },

  isPartnerAuthentication (to) {
    if (! this.isPartnerAccess()) {
      return false
    }

    return (to.path === '/' && !! to.query?.partnerKey)
  },
  async getRoutePartnerAccess (to) {
    const { query } = to

    if (! Object.values(PARTNER_PAGE_ROUTES).includes(query.page)) {
      return { name: 'homePage' }
    }

    if (query.page === PARTNER_PAGE_ROUTES.DMP) {
      return await this.routePartnerDmpAccess(query.patientIns ?? query.patientIdentity)
    }
    if (query.patientIns) {
      return await this.routePartnerPatientInsAccess(query.patientIns, query.page)
    }
    if (query.patientIdentity) {
      return await this.routePartnerPatientIdentityAccess(query.patientIdentity, query.page)
    }

    return { name: 'homePage' }
  },
  async searchPatient (patientEncryptedCriterias) {
    try {
      const { patientId: patientUuid } = await this.searchPatientUuid(patientEncryptedCriterias)
      await store.dispatch('patient/fetchOnePatient', patientUuid)
      const patient = store.getters['patient/getPatientByUUID'](patientUuid)
      return patient
    } catch(e) {
      return null
    }
  },
  async searchPatientUuid (patientEncryptedCriterias) {
    try {
      const { data } = await getFromAPI(
        '/api/v1/partner/search_patient', { data: patientEncryptedCriterias }, { errorHandle: false })
      return data
    } catch(e) {
      return null
    }
  },
  async routePartnerPatientInsAccess (patientIns, page) {
    try {
      if (page === PARTNER_PAGE_ROUTES.LAP) {
        const routeLap = await this.routePartnerPatientLap(patientIns)
        if (Object.keys(routeLap).length > 0) {
          return routeLap
        }
      }
      const data = await this.searchPatientUuid(patientIns)
      if (data.patientId && data.patientId !== null) {
        return {
          name: page === PARTNER_PAGE_ROUTES.PATIENT_FILE ? ROUTE_NAMES.PATIENT_FILE : ROUTE_NAMES.PATIENT_EDIT,
          params: { uuid: data.patientId },
        }
      }
    } catch(e) {}

    return { name: ROUTE_NAMES.PATIENT_CREATE }
  },
  async routePartnerPatientIdentityAccess (patientIdentity, page) {
    let patientIdentityDecrypted = {}
    try {
      if (page === PARTNER_PAGE_ROUTES.LAP) {
        const routeLap = await this.routePartnerPatientLap(patientIdentity)
        if (Object.keys(routeLap).length > 0) {
          return routeLap
        }
      }
      const data = await this.searchPatientUuid(patientIdentity)
      if (data.transactionId) {
        store.commit('patient/SET_PARTNER_TRANSACTION_ID', data.transactionId)
      }
      if (data.patientId && data.patientId !== null) {
        return {
          name: page === PARTNER_PAGE_ROUTES.PATIENT_FILE ? ROUTE_NAMES.PATIENT_FILE : ROUTE_NAMES.PATIENT_EDIT,
          params: { uuid: data.patientId },
        }
      }
      if (data.birthName && data.firstName && data.birthDate && data.birthPlaceCode && data.gender) {
        const dataDecrypted = new URLSearchParams({
          ...data,
          familyName: data.birthName,
          firstNames: data.firstName,
        })
        patientIdentityDecrypted = btoa(dataDecrypted.toString())
      }
    } catch(e) {
      NovaTools.notify.error('Données d\'accès incorrectes, redirection sur le formulaire de création patient')
    }

    return {
      name: ROUTE_NAMES.PATIENT_CREATE,
      params: { patientIdentity: patientIdentityDecrypted },
    }
  },
  async routePartnerPatientLap (patientData) {
    try {
      const { data } = await postToAPI(
        '/api/v1/partner/create_consultation_lap',
        { payload: decodeURIComponent(patientData) },
        { errorHandle: false },
      )
      return {
        name: ROUTE_NAMES.CREATE_PRESCRIPTION,
        params: {
          uuid: data.patientId,
          consultationUUID: data.consultationId,
        },
      }
    } catch(e) {
      return {
        name: BASE_ROUTE_NAMES.PARTNER_ERROR_PAGE,
        params: { message: 'Patient ou lieu d\'activité non connu' },
      }
    }
  },
  async routePartnerDmpAccess (patientData) {
    const patient = await this.searchPatient(patientData)
    if (patient === null) {
      return {
        name: BASE_ROUTE_NAMES.PARTNER_ERROR_PAGE,
        params: { message: 'Données patient inconnues, merci de créer la fiche patient avant accès au DMP' },
      }
    }
    if (patient.insIdentity?.isValidated()) {
      NovaTools.icanopee.efficience.openModalIframe(patient)
      return null
    }

    return {
      name: BASE_ROUTE_NAMES.PARTNER_ERROR_PAGE,
      params: { message: 'Le status INS actuel du patient ne permet pas l\'accès au DMP' },
    }
  },
  postMessageToPartner (message) {
    this.isPartnerAccess() && window.parent.postMessage({ message }, '*')
  },
  postPatientToPartner (patient) {
    patient && this.postMessageToPartner({
      ins: patient.insIdentity?.ins,
      oid: patient.insIdentity?.oid,
      status: patient.insIdentity?.status,
      gender: patient.gender,
      civility: patient.civility,
      birthName: patient.birthName,
      familyName: patient.familyName,
      firstName: patient.firstName,
      firstNames: patient.firstNames,
      usedFirstName: patient.usedFirstName,
      birthDate: patient.birthDate,
      birthPlaceCode: patient.birthPlaceCode,
    })
  },
  postErrorToPartner (error) {
    this.isPartnerAccess() && window.parent.postMessage({ error }, '*')
  },
}